import { SWAN_STYLE_KEY_MAP, Typography } from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'

export interface Props {
  title: string
}

export const Fragment = (props: Props) => {
  useSwanStyleKeys([SWAN_STYLE_KEY_MAP.core])

  return (
    <Typography fontSkin='title-headline' as='h1'>
      {props.title}
    </Typography>
  )
}
